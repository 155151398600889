<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <span class="text-h5 font-weight-bold">{{ $t(`label.contactUs`) }}</span>
        </v-col>
      </v-row>
    </v-card>

    <v-row justify="center" class="my-8">
      <v-col cols="11" lg="9">
        <v-card class="pa-6" color="transparent" tile elevation="5">
          <div class="px-sm-6" v-html="htmlContent"></div>

          <div class="px-sm-6 pt-2 contactUs-form">
            <v-form ref="contactUsForm">
              <app-form-field v-model.trim="contactUs.name" :placeholder="$t(`label.name`)" :rules="$validation.nameRules()"></app-form-field>
              <app-form-field v-model.trim="contactUs.email" :placeholder="$t(`label.email`)" :rules="$validation.emailRules()"></app-form-field>
              <app-form-field v-model.trim="contactUs.phone" :prefix="contactUs.mobileNumberCountryCode" :placeholder="$t(`label.phone`)" :rules="$validation.mobileNumberRules()"></app-form-field>
              <app-form-field v-model.trim="contactUs.message" type="textarea" :placeholder="$t(`label.message`)" :rules="$validation.requiredRules()"></app-form-field>
            </v-form>
            <v-btn block color="black" class="rounded-lg font-weight-bold text-body-1 py-6 white--text mt-1" @click="send">
              {{ $t(`label.send`) }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from 'axios'
import { localeHelper, sharedHelper } from '@/utils'
import { API } from '@/constants'
export default {
  name: 'contactUs',
  data: () => ({
    htmlContent: '',
    contactUs: {
      mobileNumberCountryCode: '+60',
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  }),
  created() {
    this.loadHtml()
  },
  methods: {
    loadHtml() {
      let htmlPath = `/static/html/helps/en/contactUs.html`
      axios({
        method: 'get',
        url: htmlPath
      }).then((result) => {
        this.htmlContent = result.data
      })
    },
    send() {
      if (this.$refs.contactUsForm.validate()) {
        axios({
          url: `https://api.mailgun.net/v3/email.unbox.com.my/messages`,
          method: 'POST',
          auth: {
            username: 'api',
            password: API.MAINGUN_API_KEY
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: new URLSearchParams({
            from: this.contactUs.email,
            to: 'contact.us@unbox.com.my',
            subject: 'unbox - Contact Us',
            html: `<html><div><p>Name: ${this.contactUs.name}</p><p>Phone : ${this.contactUs.phone}</p><p>Email : ${this.contactUs.email}</p><p>Message : ${this.contactUs.message}</p></div></html>`
          })
        }).then((result) => {
          if (result.status == 200) {
            this.$root.$master.showSnackbarMessage(localeHelper.getMessage(`label.thanksYouForContactUs`))
            this.$refs.contactUsForm.reset()
          }
        })
      }
    }
  }
}
</script>
<style>
.contactUs-form {
  max-width: 500px;
}
</style>
